// Fanplayr Adaptor (libero) v1.0.7
// Hodor v1.6.2
(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
var log = require('__hodor/log.js');
var platform = require('__hodor/platform.js');
var state = require('__hodor/state.js');
var stash = require('__hodor/stash.js');
var _ = require('__hodor/utils.js');
var capabilities = require('__hodor/capabilities.js');
var $ = require('__hodor/jquery.js');
var JSON = window.JSON;

var user = state.user;
var page = state.page;
var product = page.product;
var cart = state.cart;
var order = state.order;
var discountCodeApplied = false;

capabilities.applyToCartUrl = origin + window.location.pathname + "?o=fnplr-funnel_abandoners_discount_code&pc=%c";

var productType = ["drive", "docs", "sifattura", "mailplus", "mailpec", "mailpersonal", "mailbusiness"];

var prodPage = isProdPage();
page.type = getPageType();

if (page.type === "order") {
  order.id = order.number = "FP-" + Math.floor(new Date().getTime() / 1000);
  order.cartAction = "repeat";
  if (stash.has("fp_code")) {
    order.discountCode = stash.get("fp_code");
  }
  trackOrder();
  stash.del("fp_code");
  stash.save();
} else if (page.type === "cart") {
  capabilities.applyToCartUrl = undefined;
  capabilities.applyToCart = applyToCart;
  setInterval(function () {
    var currentDiscountCode = $('#i_iol_cd_ok').text().trim();
    var isVisible = $('#i_iol_cd_ok').is(':visible');
    if (currentDiscountCode !== "" && isVisible && !discountCodeApplied) {
      cart.discountCode = currentDiscountCode.split(" ")[0];
      stash.del("fp_code");
      stash.set("fp_code", cart.discountCode);
      stash.save();
      discountCodeApplied = true;
      trackPage();
    } else if ((currentDiscountCode === "" || !isVisible) && discountCodeApplied) {
      cart.discountCode = "";
      stash.del("fp_code");
      stash.save();
      discountCodeApplied = false;
      trackPage();
    }
  }, 1000);
  trackPage();
} else {
  cart.cartAction = "repeat";
  checkAndSetCookies();
  trackPage();
}

function getPageType() {
  if (prodPage) {
    scrapeProduct();
    return "prod";
  }
  if (window.location.host.includes("premium") && window.location.pathname === "/") {
    return "home";
  }
  if (window.location.pathname.includes("cart")) {
    scrapeCart();
    return "cart";
  }
  if (window.location.pathname.includes("thankyou")) {
    return "order";
  }
  if (window.location.pathname.includes("/checkout/")) {
    return "checkout";
  }
  return "page";
}

function isProdPage() {
  for (var i = 0; i < productType.length; i++) {
    if (window.location.host.indexOf(productType[i]) !== -1) {
      return true;
    }
  }
}

function scrapeProduct() {
  for (var i = 0; i < productType.length; i++) {
    if (window.location.host.indexOf(productType[i]) !== -1) {
      product.name = productType[i];
      product.id = productType[i];
      product.sku = productType[i];
      product.quantity = 1;
      product.price = $('.price').first().text().includes("GRATIS") ? 0 : _.parseFloat($('.price').first().text(), 0, '.,');
      product.url = window.location.href;
      product.image = getBackgroundImageURL();
      setCookie("lastProductName", product.name);
      setCookie("lastProductUrl", product.url);
      return;
    }
  }
}

function checkAndSetCookies() {
  var lastProductName = getCookie("lastProductName");
  if (lastProductName !== null) {
    page.data.lastProductName = lastProductName;
  }

  var lastProductUrl = getCookie("lastProductUrl");
  if (lastProductUrl !== null) {
    page.data.lastProductUrl = lastProductUrl;
  }
}

function applyToCart(event) {
  if (event && event.code) {
    $("#iol_cd").val(event.code);
    window.CheckCD();
  }
  stash.set("fp_code", event.code);
  stash.save();

  window.setTimeout(function () {
    if (!/fnplr-funnel_abandoners_discount_code/.test(window.location.href)) {
      var parsedUrl = _.parseUrl(window.location.href);
      parsedUrl.params.o = 'fnplr-funnel_abandoners_discount_code';
      delete parsedUrl.params.pc;
      window.location.href = parsedUrl.compile();
    }
  }, 1000);
}

function getBackgroundImageURL() {
  // Get the background property of an element whose class starts with "logo-"  
  var backgroundProperty = $('[class^="logo-"]').css('background');
  var match = backgroundProperty.match(/url\(['"]?(.*?)['"]?\)/);
  var imageURL = match ? match[1] : null;
  return imageURL;
}

function setCookie(name, value) {
  // Set cookie expiration date (1 year from now)
  var expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);

  // Set the cookie
  document.cookie = name + "=" + encodeURIComponent(value) + "; expires=" + expirationDate.toUTCString() + "; domain=.libero.it; path=/";
}

// Function to get the value of a cookie by name
function getCookie(name) {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    if (cookie.indexOf(name + "=") === 0) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return null;
}

function scrapeCart() {
  cart.clear();
  cart.discountCode = $('#i_iol_cd_ok').text() !== "" ? $('#i_iol_cd_ok').text().split(" ")[0] : "";
  if (cart.discountCode !== "") {
    stash.set("fp_code", cart.discountCode);
    stash.save();
    discountCodeApplied = true;
  }
  cart.currency = "EUR";

  var productList = [];

  // First pass to collect all products and potential discounts
  $('.cart .row').each(function (index, element) {
    var $row = $(element);
    var productName = $row.find('.product strong').text().trim();
    var priceText = $row.find('.price').text().trim();
    var isDiscountRow = $row.find('.product').text().indexOf("Prezzo effettivo dell'aggiornamento") !== -1;

    if (productName) {
      // Regular product row
      productList.push({
        name: productName,
        price: parsePrice(priceText),
        effectivePrice: null,
        row: $row
      });
    } else if (isDiscountRow) {
      // Effective price row
      if (productList.length > 0) {
        productList[productList.length - 1].effectivePrice = parsePrice(priceText);
      }
    }
  });

  // Process bundles
  $('.cart .bundle').each(function (index, element) {
    var $bundle = $(element);
    var bundleHeader = $bundle.find('header').text().trim();
    var bundlePriceText = $bundle.find('.price').first().text().trim();

    productList.push({
      name: bundleHeader,
      price: parsePrice(bundlePriceText),
      effectivePrice: null,
      row: $bundle
    });
  });

  // Add products and bundles to the cart
  productList.forEach(function (product) {
    var finalPrice = product.effectivePrice !== null ? product.effectivePrice : product.price;
    cart.products.add({
      name: product.name,
      price: finalPrice,
      quantity: 1,
      id: product.name.replace(/ -/g, '').replace(/ /g, '-'),
      sku: product.name.replace(/ -/g, '').replace(/ /g, '-')
    });
  });

  cart.updateTotals();
}

function parsePrice(priceText) {
  return parseFloat(priceText.replace(',', '.').replace(/[^\d.]/g, ''));
}

function trackPage() {
  // if (page.type === "cart") {
  //   log(JSON.stringify(state.cart, null, 2));
  // } else {
  //   log(JSON.stringify(state.page, null, 2));
  // }
  if (window.location.host !== "www.libero.it") {
    platform.trackPage();
  }
}

function trackOrder() {
  // log(JSON.stringify(state.order, null, 2));
  platform.trackOrder();
}

window.openChat = function () {
  window.zE.activate();
};

},{"__hodor/capabilities.js":4,"__hodor/jquery.js":8,"__hodor/log.js":9,"__hodor/platform.js":10,"__hodor/stash.js":11,"__hodor/state.js":12,"__hodor/utils.js":13}],2:[function(require,module,exports){
"use strict";

var Emitter = require("./emitter");

// `adaptor` is declared at the top of the IIFE during the build process.
var adaptor = module.exports = {};

var fanplayr = window.fanplayr = window.fanplayr || {};
adaptor.api = window.fanplayr_api = window.fanplayr_api || [];

Emitter.mixin(adaptor);

var log = adaptor.log = require("./log");
var config = adaptor.config = require("./config");
var _ = adaptor.utils = require("./utils");

adaptor.isInFrame = function () {
  var result = true;
  try {
    var w = window;
    result = w.top === w || w.top === w.self || !w.frameElement;
  } catch (ex) {}
  return !result;
};

var isCollector = config.collector;

if (config.version) {
  adaptor.version = config.version;
}

var didInit;
function init(fanplayr) {
  if (!didInit) {
    didInit = true;

    if (config.accountKey) {
      fanplayr.platform.config.accountKey = config.accountKey;
    }

    var platformUtils = fanplayr.require("platform/utils");
    for (var key in platformUtils) {
      _[key] = platformUtils[key];
    }

    adaptor.createStash = fanplayr.require("platform/stash").create;

    var stash = adaptor.stash = adaptor.createStash(_.merge({
      name: "adaptor",
      scope: "domain"
    }, config.stash || {}));

    adaptor.fanplayr = fanplayr;
    adaptor.$ = fanplayr.$;
    adaptor._ = _;

    stash.load(function () {
      var entry = require('__project/main.js');

      fanplayr.adaptor = _.merge({}, adaptor, entry || {});

      adaptor.emit("ready", fanplayr, fanplayr.$, _);
    });
  }
}

var isInFrame = adaptor.isInFrame();
if (!isInFrame || fanplayr.allowInFrame || config.allowInFrame) {
  // Prevent adaptor from loading multiple times.
  if (!fanplayr.adaptor || isCollector) {
    fanplayr.adaptor = adaptor;

    var initPlatform = function initPlatform() {
      // Boot fanplayr.
      require("./fanplayr-init")(init, adaptor.environment, config);
    };

    var bootFn = require('__hodor/boot.js');
    if (typeof bootFn === "function") {
      bootFn(initPlatform);
    } else {
      // Give implementing adaptor a chance to modify `adaptor.environment`.
      setTimeout(initPlatform, 0);
    }
  } else {
    log("Adaptor already loaded. Skip.");
    var reloadFn = fanplayr.adaptor && fanplayr.adaptor.onReload;
    if (typeof reloadFn === "function") {
      reloadFn();
    }
  }
} else {
  log("Adaptor prevent loading in frame: " + (window.frameElement && window.frameElement.src));
}

},{"./config":5,"./emitter":6,"./fanplayr-init":7,"./log":9,"./utils":13,"__project/main.js":1,"__hodor/boot.js":3}],3:[function(require,module,exports){
// Empty boot file that does nothing.
"use strict";

},{}],4:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform.capabilities;

},{"./adaptor":2}],5:[function(require,module,exports){
"use strict";

// Magically replaced, do not edit.
module.exports = {
  "type": "adaptor",
  "name": "libero",
  "version": "1.0.7",
  "accountKey": "c2c7bf10f7059871e78b45891586d1a8",
  "adaptorVersion": 1
};

},{}],6:[function(require,module,exports){
"use strict";

function Emitter() {
  this.events = {};

  // Last emitted values.
  this.emitCache = {};
}

var arraySlice = Array.prototype.slice;

Emitter.mixin = function (obj) {
  var emitter = new Emitter();

  obj.on = function () {
    return emitter.on.apply(emitter, arraySlice.call(arguments));
  };

  obj.emit = function () {
    return emitter.emit.apply(emitter, arraySlice.call(arguments));
  };

  obj.$emitter = emitter;

  return obj;
};

Emitter.prototype = {
  on: function on(eventType, options, cb) {
    var self = this;

    if (typeof options === "function") {
      var temp = cb;
      cb = options;
      options = temp;
    }

    if (!options) {
      options = {};
    }

    var listeners = self.events[eventType];
    if (!listeners) {
      listeners = self.events[eventType] = [];
    }

    var listener = {
      options: options,
      cb: cb
    };

    if (options.init) {
      var cached = self.emitCache[eventType];
      if (cached) {
        self.invoke(listener, cached);
      }
    }

    listeners.push(listener);
  },

  emit: function emit(eventType) {
    var self = this;

    var args = arraySlice.call(arguments, 1);
    self.emitCache[eventType] = args;

    var listeners = self.events[eventType];
    if (listeners) {
      for (var i = 0, len = listeners.length; i < len; i++) {
        self.invoke(listeners[i], args);
      }
    }
  },

  invoke: function invoke(listener, args) {
    listener.cb.apply(listener.options.context || listener.cb, args);
  }
};

module.exports = Emitter;

},{}],7:[function(require,module,exports){
"use strict";

var log = require("./log");

module.exports = function (initFn, environment, config) {
  (function (window, document) {
    var fanplayr = window.fanplayr;
    var fanplayrReady = "fanplayr_ready";

    /*
      fanplayr._loadState
        falsy: not initalized
        1: loading
        2: ready
    */

    if (fanplayr) {
      var loadState = fanplayr._loadState;

      if (loadState === 2) {
        // Already loaded.
        initFn(fanplayr);
      }

      var origFn = window[fanplayrReady];
      window[fanplayrReady] = function (f, p) {
        if (origFn) {
          origFn(f, p);
        }
        initFn(f);
      };

      if (!loadState) {
        // Fanplayr hasn't begun loading, so load it.
        var script = document.createElement("script");
        script.async = true;
        script.src = "//cdn.fanplayr.com/client/" + (environment || "production") + "/loader.js";
        var first = document.getElementsByTagName("script")[0];
        var mode = config.injectMode || 'beforeFirstScript';
        if (first && mode === 'beforeFirstScript') {
          first.parentNode.insertBefore(script, first);
        } else if (document.head && mode === 'head') {
          document.head.appendChild(script);
        } else if (document.body) {
          document.body.appendChild(script);
        }
      }
    }
  })(window, document);
};

},{"./log":9}],8:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.$;

},{"./adaptor":2}],9:[function(require,module,exports){
"use strict";

var console, logFn;

try {
  console = window.console || window.top.console;
  logFn = console && console.log;
} catch (e) {}

var noop = function noop() {};

function create(name) {
  var log = noop;
  if (logFn) {
    if (typeof logFn.bind === "function") {
      log = logFn.bind(console, "[" + name + "]");
    } else {
      log = function log() {
        var args = Array.prototype.slice.call(arguments);
        args.unshift("[" + name + "]");
        logFn(args.join(""));
      };
    }
  }

  log.create = create;
  log.info = log;
  log.error = log;

  return log;
}

module.exports = create("adaptor");

},{}],10:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform;

},{"./adaptor":2}],11:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").stash;

},{"./adaptor":2}],12:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform.state;

},{"./adaptor":2}],13:[function(require,module,exports){
"use strict";

// modules.exports = require("./req")("platform/utils");

var _ = module.exports = {};
var log = require("./log");

_.parseInt = function (text, defaultValue) {
  var value = _parseFloat(text, defaultValue);
  if (typeof value === "number") {
    return Math.floor(value);
  }
  return value;
};

/*
  Extracts the first matched float from a string.

  @param string text       The input string.
  @param * defaultValue    The default value to return if parsing fails.
  @param string chars      Special characters.

  About `chars`:
    - Must include at least two characters.
    - The first character must be the thousands separator.
    - The second character must be the decimal character.
    - Any other characters will be matched as part of the number, but stripped before converting to a float.
*/
_.parseFloat = _parseFloat;
function _parseFloat(text, defaultValue, chars) {
  // Get defaults.
  if (typeof defaultValue === "undefined") {
    defaultValue = _parseFloat.defaultValue;
  }
  chars = chars || _parseFloat.chars;

  text = String(text);
  var value = defaultValue;
  if (text) {
    // Find the first occurance of a group of numbers and special characters. Must match:
    // - Optional negative character `-`
    // - Optional single special character
    // - One or more digits
    // - Zero or more digits and special characters
    var pattern = new RegExp("(-?[" + chars.substr(0, 2) + "]?\\d+[0-9" + chars + "]*)");
    var match = text.match(pattern);
    if (match) {
      // Build a regular expression of bad characters to strip out.
      // The first char is the separator.
      var badChars = chars.charAt(0);
      if (chars.length > 2) {
        // Add any others.
        badChars += chars.substr(2);
      }
      pattern = new RegExp("[" + badChars + "]", "g");

      // Strip bad characters.
      text = match[1].replace(pattern, "");

      // Replace the decimal character with an actual period
      // and parse as float.
      value = parseFloat(text.replace(chars.charAt(1), "."));

      if (isNaN(value)) {
        return defaultValue;
      }
    }
  }
  return value;
};

_parseFloat.defaultValue = 0;
_parseFloat.chars = ",.";

var decodeHtmlEl;
_.decodeHtml = function (value) {
  if (value) {
    if (!decodeHtmlEl) {
      decodeHtmlEl = document.createElement("div");
    }
    decodeHtmlEl.innerHTML = value;
    return decodeHtmlEl.firstChild.nodeValue;
  }
  return value;
};

_.waitUntil = function (evaluate, intervalMs, callback) {
  if (arguments.length === 2) {
    callback = intervalMs;
    intervalMs = 500;
  }

  if (typeof evaluate === "string") {
    var evalPath = evaluate;
    evaluate = function evaluate() {
      return eval(evalPath);
    };
  }

  var iterator = function iterator() {
    var value = evaluate();
    if (value) {
      callback(value);
    } else {
      setTimeout(iterator, intervalMs);
    }
  };

  iterator();
};

},{"./log":9}]},{},[2]);